import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"

export default () => (
  <Layout title="Page not found" description="Page not found">
    <>
      <Navbar />
      <div className="page-not-found-container">
        <div className="heading"></div>
        <div className="layout-container">
          <div role="img" aria-label="Emojis" className="text-center emoji">
            😣😭😫😞
          </div>
          <h3 className="text-primary text-center">PAGE NOT FOUND</h3>
          <div className="content">
            <p>
              We're sorry, but the page you're looking for has been moved or
              does not exist.
            </p>
            <div className="margin-top-4">
              <Link to="/" className="btn primary-btn full-width">
                BACK TO HOME PAGE
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  </Layout>
)
